import axios from 'axios'
import Vue from 'vue'
let lang = {
  "zh-CN": "zh",
  "zh-TW": "zhtw",
  "en": "en"
}
const service = axios.create({
  //baseURL: 'http://www.ysh.com/webapi/',
  baseURL: 'https://cdshapi.orangeevcharge.com/webapi/',
  withCredentials: false,
  timeout: 30000,
  params: {
    language: lang[window.sessionStorage.getItem('orange_lang')]
  }
})

// 添加请求拦截器
service.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  //console.log('config',config)
  config.headers.Authorization = `Bearer ${window.sessionStorage.getItem('token')}`
  // config.headers['Access-Control-Allow-Origin'] = `*`
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})
service.interceptors.response.use(function (res) {
  // 响应拦截
  // console.log('res',res)
  if (res.data.code === 1003) {
    document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    document.cookie = 'phone=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    window.sessionStorage.removeItem('token')
    Vue.prototype.$router.push({
      name: 'Login'
    })
    Vue.prototype.$message.error('请重新登录')
  }
  return res
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})
export default service
