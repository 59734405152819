import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)
// import Login from '../views/login.vue'
// import Index from '../views/index/index.vue'

const routes = [{
  path: '/login',
  name: 'Login',
  component: () => import('../views/login.vue')
},
{
  path: '/',
  name: 'Home',
  component: Home,
  redirect: '/index',
  children: [{
    path: 'index',
    name: 'Index',
    component: () => import('../views/index/index.vue')
  },
  {
    path: 'Subscribe',
    name: 'Subscribe',
    component: () => import('../views/declaration/subscribe')
  },
  {
    path: 'QRcode',
    name: 'QRcode',
    component: () => import('../views/declaration/QRcode')
  },
  {
    path: 'QueryOrder',
    name: 'QueryOrder',
    component: () => import('../views/orderManage/QueryOrder')
  },
  {
    path: 'OrderFeedback',
    name: 'OrderFeedback',
    component: () => import('../views/orderManage/OrderFeedback')
  },
  {
    path: 'orderParts',
    name: 'orderParts',
    component: () => import('../views/orderManage/orderParts')
  },
  {
    path: 'batchCreateOrder',
    name: 'batchCreateOrder',
    component: () => import('../views/orderManage/batchCreateOrder')
  },
  {
    path: 'appeal',
    name: 'appeal',
    component: () => import('../views/orderManage/appeal')
  },
  {
    path: 'QueryCustomer',
    name: 'QueryCustomer',
    component: () => import('../views/customerManage/QueryCustomer')
  },
  {
    path: 'contact',
    name: 'contact',
    component: () => import('../views/customerManage/contact')
  },
  {
    path: 'product',
    name: 'product',
    component: () => import('../views/customerManage/product')
  },
  {
    path: 'producType',
    name: 'producType',
    component: () => import('../views/customerManage/producType')
  },
  {
    path: 'queryProject',
    name: 'queryProject',
    component: () => import('../views/projectManage/queryProject')
  },
  {
    path: 'customerOrderNum',
    name: 'customerOrderNum',
    component: () => import('../views/Statistics/customerOrderNum')
  },
  {
    path: 'internalOrderNum',
    name: 'internalOrderNum',
    component: () => import('../views/Statistics/internalOrderNum')
  },
  {
    path: 'projectOrder',
    name: 'projectOrder',
    component: () => import('../views/Statistics/projectOrder')
  },
  {
    path: 'WarrantyType',
    name: 'WarrantyType',
    component: () => import('../views/Statistics/WarrantyType')
  },
  {
    path: 'equipmentType',
    name: 'equipmentType',
    component: () => import('../views/Statistics/equipmentType')
  },
  {
    path: 'partsHandle',
    name: 'partsHandle',
    component: () => import('../views/Statistics/partsHandle')
  },
  {
    path: 'grade',
    name: 'grade',
    component: () => import('../views/Statistics/grade')
  },
  {
    path: 'productType',
    name: 'productType',
    component: () => import('../views/basicData/productType')
  },
  {
    path: 'productList',
    name: 'productList',
    component: () => import('../views/basicData/productList')
  },
  {
    path: 'CheckInRecord',
    name: 'CheckInRecord',
    component: () => import('../views/Attendance/CheckInRecord')
  },
  {
    path: 'extraPoint',
    name: 'extraPoint',
    component: () => import('../views/Attendance/extraPoint')
  },
  {
    path: 'Distribution',
    name: 'Distribution',
    component: () => import('../views/system/Distribution')
  },
  {
    path: 'department',
    name: 'department',
    component: () => import('../views/system/department')
  },
  {
    path: 'admin',
    name: 'admin',
    component: () => import('../views/system/admin')
  },
  {
    path: 'region',
    name: 'region',
    component: () => import('../views/customerManage/region')
  },
  {
    path: 'WarningCode',
    name: 'WarningCode',
    component: () => import('../views/customerManage/WarningCode')
  },
  {
    path: 'weChart',
    name: 'weChart',
    component: () => import('../views/customerManage/weChart')
  },
  {
    path: 'orderDet',
    name: 'orderDet',
    component: () => import('../views/orderManage/OrderDet')
  },
  {
    path: 'WarrMess',
    name: 'WarrMess',
    component: () => import('../views/ThirdParty/WarrMess')
  },
  {
    path: 'ASFault',
    name: 'ASFault',
    component: () => import('../views/Statistics/ASFault')
  },
  {
    path: 'subOrder',
    name: 'subOrder',
    component: () => import('../views/declaration/subOrder')
  },
  {
    path: 'createOrder',
    name: 'createOrder',
    component: () => import('../views/declaration/createOrder')
  },
  {
    path: 'RoleLack',
    name: 'RoleLack',
    component: () => import('../views/system/RoleLack')
  }
  ]
}
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem('token')
  if (!token && to.name !== 'Login') return next('/login')
  else next()
})
router.afterEach((to, from) => {
  const user_role = JSON.parse(window.localStorage.getItem('user_role'))
  const item = {
    Subscribe: 'subscribe/lists',
    createOrder: 'work_order/add',
    subOrder: 'subscribe/add',
    QueryOrder: 'work_order/lists',
    QueryCustomer: 'customer/lists',
    contact: 'customer_contacts/lists',
    region: 'region/lists',
    product: 'equipment/lists',
    producType: 'equipment_model/lists',
    customerOrderNum: 'report/customer_worder',
    internalOrderNum: 'report/personnel_worder',
    WarrantyType: 'report/warranty_worder',
    equipmentType: 'report/equipment_type_statistics',
    partsHandle: 'report/alarm_information_statistics',
    ASFault: 'report/dg_fault_statistics',
    CheckInRecord: 'work_order/sign_list',
    Distribution: 'role/lists',
    admin: 'admin/lists',
    department: 'department/lists',
    WarrMess: 'platform/get_warning_info',
    WarningCode: 'warning_code/lists'
  }
  if (user_role) {
    if (item[to.name]) user_role[item[to.name]] ? true : router.push('/RoleLack')
  } else {
    console.log('权限获取失败')
  }
})
export default router
