import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../utils/http.js'
Vue.use(Vuex)
/* state变量，mutations事件 */
export default new Vuex.Store({
  state: {
    ocList: [],
    RLid: [],
    ERid: [],
    codeList: [],
    minList: []
  },
  mutations: {
    setCid (state, data) {
      state.ocList = data
    },
    setRid (state, data) {
      state.RLid = data
    },
    setEid (state, data) {
      state.ERid = data
    },
    setCode (state, data) {
      state.codeList = data
    },
    setAdmin (state, data) {
      state.minList = data
    }
  },
  actions: {
    // 所有客户
    getCid ({ commit }) {
      axios.post('customer/getAll').then((res) => {
        var data = res.data
        if (data.code === 0) {
          commit('setCid', data.data)
          window.localStorage.setItem('orange_cid', JSON.stringify(data.data))
        } else {
          console.warn(data.message)
        }
      }).catch((err) => console.error(err))
    },
    // 客户所有区域
    getRid ({ commit }) {
      axios.post('region/getAll').then((res) => {
        var data = res.data
        if (data.code === 0) {
          commit('setRid', data.data)
          window.localStorage.setItem('orange_rid', JSON.stringify(data.data))
        } else {
          console.warn(data.message)
        }
      }).catch((err) => console.error(err))
    },
    // 客户区域设备
    getEid ({ commit }) {
      axios.post('equipment/getAll').then((res) => {
        var data = res.data
        if (data.code === 0) {
          commit('setEid', data.data)
          window.localStorage.setItem('orange_eid', JSON.stringify(data.data))
        } else {
          console.warn(data.message)
        }
      }).catch((err) => console.error(err))
    },
    // 获取错误码
    getCode ({ commit }) {
      axios.post('warning_code/getAll').then((res) => {
        var data = res.data
        if (data.code === 0) {
          commit('setCode', data.data)
          window.localStorage.setItem('orange_code', JSON.stringify(data.data))
        } else console.warn(data.message)
      }).catch((err) => console.error(err))
    },
    // 获取管理员名单
    getAdmin ({ commit }) {
      axios.post('admin/getAll').then((res) => {
        var data = res.data
        if (data.code === 0) {
          commit('setAdmin', data.data)
        } else console.warn(data.message)
      }).catch((err) => console.error(err))
    },
    // 添加新站点
    addSite ({ commit },params) {
      //console.log(params)
      axios.post('region/add', params).then((res) => {
        if (res.data.code === 0) {
          // 清空表单
          alert(res.data.message)
        } else {
          alert(res.data.message)
        }
      }).catch((error) => console.log(error))
    }
  },
  modules: {
  }
})
