import Vue from 'vue'
import {
  Button, Menu, MenuItem, Submenu,
  Card, Row, Form, FormItem,
  Input, Image, Checkbox, Container,
  Header, Aside, Main, MenuItemGroup,
  Dropdown, DropdownItem, DropdownMenu,
  Badge, Tabs, TabPane, DatePicker,
  Table, TableColumn, Dialog, Select,
  Option, Col, Upload, Message,
  Tree, Pagination, MessageBox, Loading,
  Autocomplete,Tooltip
} from 'element-ui'

Vue.use(Button)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Card)
Vue.use(Row)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Image)
Vue.use(Checkbox)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(MenuItemGroup)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Badge)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Tree)
Vue.use(Pagination)
Vue.use(Loading)
Vue.use(Autocomplete)
Vue.use(Tooltip)
Vue.prototype.$message = Message
Vue.prototype.$MessageBox = MessageBox
Vue.prototype.$loading = Loading.service
