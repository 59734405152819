<template>
  <div class="content">
    <div class="left">
      <div class="Logo"></div>
    </div>
    <div class="right">
      <div class="Language">
        <el-dropdown :show-timeout="100" placement="bottom" @command="chargeLanguage">
          <span class="el-dropdown-link">
            <i class="bi bi-globe"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh-CN">简体中文</el-dropdown-item>
            <el-dropdown-item command="zh-TW">繁体中文</el-dropdown-item>
            <el-dropdown-item command="en">English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div><!-- /notice -->
      <div class="notice">
        <el-dropdown :show-timeout="100" placement="bottom">
          <span class="el-dropdown-link">
            <i class="bi bi-envelope"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="bi bi-bell">{{$t('查看通知')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div><!-- /notice -->
      <div class="userName">
        <el-dropdown :show-timeout="100" placement="bottom" @command="handleCommand">
          <span class="el-dropdown-link">{{userName}}</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="bi bi-qr-code-scan" command="bindWX">{{$t('绑定微信')}}</el-dropdown-item>
            <el-dropdown-item icon="bi bi-shield-lock" command="ChangePassword">{{$t('修改密码')}}</el-dropdown-item>
            <el-dropdown-item icon="bi bi-power" command="logOut">{{$t('退出登录')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div><!-- /userName -->
      <div class="service">
        <el-dropdown :show-timeout="100" placement="bottom">
          <span class="el-dropdown-link">
            {{$t('人工客服')}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="bi bi-telephone-forward">150-1326-2980</el-dropdown-item>
            <el-dropdown-item>QQ: 25895226</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div><!-- /service -->
    </div><!-- right -->
    <el-dialog :title="$t('修改密码')" :visible.sync="isChangePassword" width="30%">
      <el-row :gutter="20">
        <el-form :model="form" label-width="100px" label-position="left" ref="form" :rules="rules">
          <el-col :span="24">
            <el-form-item :label="$t('账号')" prop="phone">
              <el-input disabled v-model="form.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('新密码')" prop="newPassword">
              <el-input v-model.number="form.newPassword" show-password></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('确认新密码')" prop="confirmPassword">
              <el-input v-model.number="form.confirmPassword" show-password></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('旧密码')" prop="oldPassword">
              <el-input v-model.number="form.oldPassword" show-password></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isChangePassword = false">{{$t('取消')}} </el-button>
        <el-button type="primary" @click="ChangePassword">{{$t('确定')}} </el-button>
      </div>
    </el-dialog>
    <!-- 绑定微信 -->
    <el-dialog :title="$t('绑定微信')" :visible.sync="isbindWX">
      <el-row :gutter="20">
        <el-form>
          <el-col :span="24">
            <el-form-item prop="QRcode" style="display: flex;justify-content: center;align-items: center;">
              <!-- <el-image src="../../assets/img/code.png"></el-image> -->
              <!-- <img src="../../assets/img/code.png" /> -->
              <iframe :src="bindCodeUrl" width="500" height="400"></iframe>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="isbindWX = false">取 消</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    var confirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('请再次输入密码')))
      } else if (value !== this.form.newPassword) {
        callback(new Error(this.$t('两次输入密码不一致')))
      } else {
        callback()
      }
    }
    return {
      userName: '',
      isChangePassword: false,
      isbindWX: false,
      form: {
        phone: '',
        newPassword: '',
        confirmPassword: '',
        oldPassword: ''
      },
      // 校验规则
      rules: {
        newPassword: [{
          required: true,
          message: this.$t('请输入新密码'),
          trigger: 'blur'
        }, {
          type: 'number',
          message: this.$t('密码必须是数字')
        }],
        confirmPassword: [{
          required: true,
          validator: confirmPassword,
          trigger: 'blur'
        },
        {
          type: 'number',
          message: this.$t('密码必须是数字')
        }
        ],
        oldPassword: [{
          required: true,
          message: this.$t('请输入旧密码'),
          trigger: 'blur'
        }, {
          type: 'number',
          message: this.$t('密码必须是数字')
        }]
      },
      bindCodeUrl: ''
    }
  },
  mounted () {
    this.getUserName()
    // console.log(this.$axios.defaults.baseURL)
    if (document.domain !== 'localhost') {
      document.domain = 'orangeevcharge.com'
    }
    this.bindCodeUrl = `${this.$axios.defaults.baseURL}admin/bind_wechat?token=${window.sessionStorage.getItem('token')}&`
    //
    var that = this
    window.scanAuthorizationSuccess = function () {
      that.isbindWX = false
      that.$message.success(that.$t('绑定成功'))
    }
  },
  methods: {
    // 获取用户名称
    getUserName () {
      this.userName = this.$common.getCookie('username')
    },
    // 退出登录
    LogOut () {
      this.$axios.post('admin/logout').then((res) => {
        // console.log('logout', res)
        if (res.data.code === 0) {
          document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
          document.cookie = 'phone=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
          window.sessionStorage.removeItem('token')
          window.localStorage.clear()
          this.$router.push({
            name: 'Login'
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 修改密码
    ChangePassword () {
      this.$common.Throttle(() => {
        this.$axios.post('admin/setpassword', {
          password1: this.form.newPassword,
          password2: this.form.confirmPassword,
          oldpassword: this.form.oldPassword
        }).then((res) => {
          // console.log('setpassword', res)
          if (res.data.code === 0) {
            this.$message.success(res.data.message)
            this.isChangePassword = false
            this.$common.resetField('form', this)
          } else {
            this.$message.error(res.data.message)
          }
        }).catch((e) => {
          console.log(e)
        })
      }, 3000)()
    },
    // 判断点击的是哪个item
    handleCommand (command) {
      if (command === 'logOut') this.LogOut()
      if (command === 'ChangePassword') {
        this.form.phone = this.$common.getCookie('phone')
        this.isChangePassword = true
      }
      if (command === 'bindWX') { this.isbindWX = true }
    },
    // 跳转绑定页
    getQRcode () {
      // this.$axios.get('admin/bind_wechat').then((res) => {
      //   console.log(res)
      // }).catch(err => console.log(err))
    },
    // 切换语言
    chargeLanguage(command){
      window.sessionStorage.setItem('orange_lang',command)
      // this.$i18n.locale = command
      // let params = {
      //   "zh-CN": "zh",
      //   "zh-TW": "zhtw",
      //   "en": "en"
      // }
      location.reload(true)
    },
    // 更换后端配置语言,该函数暂不使用
    setLanguage(language){
      this.$axios.post('admin/setLanguage',{language}).then((res)=>{
          //console.log(res.data.message)
      }).catch((err)=>{})
    }
  }
}
</script>

<style scoped>
  .content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* 30px */
    padding: 0 1.875rem;
    /*  background: linear-gradient(to bottom,#ff5c1c,#ffffff); */
    /* background-color: #ff5c1c; */
    /* border-bottom: 1px solid #ff5c1c; */
    box-shadow: 0 0 5px #b1b1b1;
  }

  .left {
    height: 100%;
  }

  .right {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .right>* {
    flex: 1;
    white-space: nowrap;
    /* 25px */
    padding: 0 1.5625rem;
  }

  .Logo {
    width: 200px;
    height: 100%;
    /* border: 1px solid #ff5c1c; */
  }

  .el-dropdown-menu::v-deep {
    padding: 0 !important;
    /* width: 150px; */
    white-space: nowrap;
  }

  .el-dropdown-link {
    user-select: none;
  }

  .el-input.is-disabled::v-deep .el-input__inner {
    color: #575757;
  }

  ::v-deep .el-input__inner {
    width: 100% !important;
  }
  iframe{
    border: 0 !important;
  }
</style>
