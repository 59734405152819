<template>
  <el-container>
    <el-header>
      <TopBar></TopBar>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <Sidebar></Sidebar>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import TopBar from './plate/topBar.vue'
import Sidebar from './plate/sidebar.vue'
export default {
  name: 'Home',
  data () {
    return {
      user_role: {},
      commitStore: {
        cid: 'setCid',
        rid: 'setRid',
        eid: 'setEid',
        code: 'setCode'
      },
      dispatchStore: {
        cid: 'getCid',
        rid: 'getRid',
        eid: 'getEid',
        code: 'getCode'
      }
    }
  },
  components: {
    Sidebar,
    TopBar
  },
  created () {
    // console.log()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
    var orangeInfo, key
    orangeInfo = {
      cid: window.localStorage.getItem('orange_cid'),
      rid: window.localStorage.getItem('orange_rid'),
      eid: window.localStorage.getItem('orange_eid'),
      code: window.localStorage.getItem('orange_code')
    }
    for (key in orangeInfo) {
      if (orangeInfo[key] !== '' && orangeInfo[key] !== null) {
        this.$store.commit(this.commitStore[key], JSON.parse(orangeInfo[key]))
      } else {
        this.$store.dispatch(this.dispatchStore[key])
      }
    }
  },
  mounted () {},
  methods: {}
}
</script>

<style type="text/css" scoped>
  ::v-deep .el-header{
    padding: 0;
  }
   .el-container ::v-deep{
    min-height: 700px;
    height: 100%;
  }
</style>
