<template>
  <el-menu class="el-menu-vertical-demo" background-color="#ff9252" text-color="#ffffff" unique-opened
     router @select="routeSelect">
    <el-menu-item index="0" :route="{name: 'Index'}" :class="this.$route.name==='Index'?'activeTextColor':''">
      <i class="bi bi-house-door"></i>
      <span slot="title">{{$t('首页')}}</span>
    </el-menu-item>
    <el-submenu index="1" v-if="user_role['subscribe/lists']">
      <template slot="title">
        <i class="bi bi-send"></i>
        <span>{{$t('申请报单')}}</span>
      </template>
      <!-- -->
      <el-menu-item v-for="item in barItem" v-if="item.group=='1'&&user_role[item.role]" :index="`${item.group}-${item.index}`" :route="{name: item.name}" :class="getStyle(item.name)" >{{$t(item.value)}}</el-menu-item>
      <!-- -->
    </el-submenu><!-- /1 -->
    <el-submenu index="2" v-if="user_role['work_order/lists']">
      <template slot="title">
        <i class="bi bi-clipboard2-pulse"></i>
        <span>{{$t('工单管理')}}</span>
      </template>
      <el-menu-item v-for="item in barItem" v-if="item.group=='2'&&user_role[item.role]" :index="`${item.group}-${item.index}`" :route="{name: item.name}" :class="getStyle(item.name)" >{{$t(item.value)}}</el-menu-item>
        <!-- <el-menu-item index="2-3" :route="{name: 'orderParts'}" :class="this.$route.name==='orderParts'?'activeTextColor':''">工单配件</el-menu-item> -->
        <!-- <el-menu-item index="2-4" :route="{name: 'batchCreateOrder'}" :class="this.$route.name==='batchCreateOrder'?'activeTextColor':''">批量生成工单</el-menu-item> -->
    </el-submenu><!-- /2 -->
    <el-submenu index="3"
    v-if="user_role['customer/lists']||
    user_role['customer_contacts/lists']||
    user_role['equipment/lists']||
    user_role['equipment_model/lists']||
    user_role['region/lists']||
    user_role['warning_code/lists']">
      <template slot="title">
        <i class="bi bi-people"></i>
        <span>{{$t('商家管理')}}</span>
      </template>
      <el-submenu index="3-1">
        <template slot="title">{{$t('客户管理')}}</template>
        <el-menu-item v-for="item in barItem" v-if="item.group=='3-1'&&user_role[item.role]" :index="`${item.group}-${item.index}`" :route="{name: item.name}" :class="getStyle(item.name)">{{$t(item.value)}}</el-menu-item>
      </el-submenu>
      <el-submenu index="3-2"
      v-if="user_role['equipment/lists']||
      user_role['equipment_model/lists']||
      user_role['region/lists']||
      user_role['warning_code/lists']">
        <template slot="title">{{$t('设备管理')}}</template>
        <el-menu-item v-for="item in barItem" v-if="item.group=='3-2'&&user_role[item.role]" :index="`${item.group}-${item.index}`" :route="{name: item.name}" :class="getStyle(item.name)" >{{$t(item.value)}}</el-menu-item>
      </el-submenu>
    </el-submenu><!-- /3 -->
   <!-- <el-submenu index="4">
      <template slot="title">
        <i class="bi bi-layers"></i>
        <span>项目管理</span>
      </template>
        <el-menu-item index="4-1" :route="{name: 'queryProject'}" :class="this.$route.name==='queryProject'?'activeTextColor':''">项目查询</el-menu-item>
    </el-submenu> --><!-- /4 -->
    <el-submenu index="5"
    v-if="user_role['report/customer_worder']||
    user_role['report/personnel_worder']||
    user_role['report/warranty_worder']||
    user_role['report/alarm_information_statistics']||
    user_role['report/equipment_type_statistics']">
      <template slot="title">
        <i class="bi bi-graph-up-arrow"></i>
        <span>{{$t('统计报表')}}</span>
      </template>
        <el-menu-item v-for="item in barItem" v-if="item.group=='5'&&user_role[item.role]" :index="`${item.group}-${item.index}`" :route="{name: item.name}" :class="getStyle(item.name)" >{{$t(item.value)}}</el-menu-item>
       <!-- <el-menu-item index="5-3" :route="{name: 'projectOrder'}" :class="this.$route.name==='projectOrder'?'activeTextColor':''">项目工单统计</el-menu-item> -->

    </el-submenu><!-- /5 -->
    <!-- <el-submenu index="6">
      <template slot="title">
        <i class="bi bi-file-earmark-text"></i>
        <span>基础资料</span>
      </template>
        <el-menu-item index="6-1" :route="{name: 'productType'}" :class="this.$route.name==='productType'?'activeTextColor':''">产品类型</el-menu-item>
        <el-menu-item index="6-2" :route="{name: 'productList'}" :class="this.$route.name==='productList'?'activeTextColor':''">产品目录</el-menu-item>
    </el-submenu> --><!-- /6 -->
    <el-submenu index="7" v-if="this.user_role['work_order/sign_list']">
      <template slot="title">
        <i class="bi bi-calendar-check"></i>
        <span>{{$t('考勤签到')}}</span>
      </template>
        <el-menu-item v-for="item in barItem" v-if="item.group=='7'&&user_role[item.role]" :index="`${item.group}-${item.index}`" :route="{name: item.name}" :class="getStyle(item.name)" >{{$t(item.value)}}</el-menu-item>
    </el-submenu><!-- /7 -->
    <el-submenu index="8"
    v-if="user_role['role/lists']||user_role['admin/lists']||user_role['department/lists']">
      <template slot="title">
        <i class="bi bi-key"></i>
        <span>{{$t('系统管理')}}</span>
      </template>
        <el-menu-item v-for="item in barItem" v-if="item.group=='8'&&user_role[item.role]" :index="`${item.group}-${item.index}`" :route="{name: item.name}" :class="getStyle(item.name)" >{{$t(item.value)}}</el-menu-item>

    </el-submenu><!-- /8 -->
    <el-submenu index="9" v-if="user_role['platform/get_warning_info']">
      <template slot="title">
        <i class="bi bi-infinity"></i>
        <span>{{$t('平台接入')}}</span>
      </template>
      <el-menu-item v-for="item in barItem" v-if="item.group=='9'&&user_role[item.role]" :index="`${item.group}-${item.index}`" :route="{name: item.name}" :class="getStyle(item.name)" >{{$t(item.value)}}</el-menu-item>
    </el-submenu><!-- 9 -->
  </el-menu>
</template>

<script>
export default {
  data () {
    return {
      user_role: {},
      barItem: [
        { group: '1', index: '1', name: 'Subscribe', role: 'subscribe/lists', value: '预约记录' },
        { group: '1', index: '2', name: 'subOrder', role: 'subscribe/add', value: '预约工单' },
        { group: '1', index: '3', name: 'createOrder', role: 'work_order/add', value: '生成工单' },
        { group: '2', index: '1', name: 'QueryOrder', role: 'work_order/lists', value: '工单查询' },
        { group: '2', index: '2', name: 'appeal', role: 'work_order/appealLists', value: '工单申述' },
        { group: '3-1', index: '1', name: 'QueryCustomer', role: 'customer/lists', value: '客户列表' },
        { group: '3-1', index: '2', name: 'contact', role: 'customer_contacts/lists', value: '联系人管理' },
        { group: '3-1', index: '3', name: 'region', role: 'region/lists', value: '站点管理' },
        { group: '3-1', index: '4', name: 'weChart', role: 'wxuser/lists', value: '微信用户管理' },
        { group: '3-2', index: '1', name: 'product', role: 'equipment/lists', value: '设备查询' },
        { group: '3-2', index: '2', name: 'producType', role: 'equipment_model/lists', value: '设备类型管理' },
        { group: '5', index: '1', name: 'customerOrderNum', role: 'report/customer_worder', value: '客户报单数量统计' },
        { group: '5', index: '2', name: 'internalOrderNum', role: 'report/personnel_worder', value: '售后人员工单统计' },
        { group: '5', index: '4', name: 'WarrantyType', role: 'report/warranty_worder', value: '质保类型统计' },
        { group: '5', index: '5', name: 'equipmentType', role: 'report/equipment_type_statistics', value: '设备类型统计' },
        { group: '5', index: '6', name: 'partsHandle', role: 'report/alarm_information_statistics', value: '平台故障类型统计' },
        { group: '5', index: '7', name: 'ASFault', role: 'report/dg_fault_statistics', value: '工单故障类型统计' },
        { group: '5', index: '8', name: 'grade', role: 'report/after_sales_acore', value: '售后评分统计' },
        { group: '7', index: '1', name: 'CheckInRecord', role: 'work_order/sign_list', value: '签到记录' },
        { group: '7', index: '2', name: 'extraPoint', role: 'additionalPoints/lists', value: '附加分项' },
        { group: '8', index: '1', name: 'Distribution', role: 'role/lists', value: '角色管理' },
        { group: '8', index: '2', name: 'admin', role: 'admin/lists', value: '管理员管理' },
        { group: '8', index: '3', name: 'department', role: 'department/lists', value: '部门管理' },
        { group: '9', index: '1', name: 'WarrMess', role: 'platform/get_warning_info', value: '平台告警信息' },
        { group: '9', index: '2', name: 'WarningCode', role: 'warning_code/lists', value: '警告码管理' }
      ]
    }
  },
  created () {
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
  },
  computed: {},
  methods: {
    routeSelect (index, indexPath) {
      // console.log(this.$route.name)
    },
    getStyle (name) {
      return this.$route.name === name ? 'activeTextColor' : ''
    }
  }
}
</script>

<style scoped>
  *{
    /* user-select:none; */
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  }
  .el-menu::v-deep {
    height: 100%;
    /* 350px */
    padding: 0 0 21.875rem 0 !important;
    /* background: linear-gradient(to right, #ff782a, #ffffff); */
  }

  .bi {
    color: #FFFFFF;
    margin: 0 10px 0 5px;
    font-size: 18px;
  }

  ::v-deep .el-icon-arrow-down::before {
    color: #FFFFFF;
  }
  ::v-deep .activeTextColor{
    /* color: #134673 !important; */
    color: #FFFFFF !important;
    background: linear-gradient(to right, #134673,#ff9252) !important;
  }
</style>
