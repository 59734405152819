import Vue from 'vue'

import * as echarts from 'echarts/core'
import {
  LineChart, PieChart
} from 'echarts/charts'
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LegendComponent
} from 'echarts/components'
import {
  UniversalTransition, LabelLayout
} from 'echarts/features'
import {
  CanvasRenderer
} from 'echarts/renderers'
echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  LabelLayout,
  PieChart,
  LegendComponent
])

Vue.prototype.$echarts = echarts
