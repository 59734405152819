<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'app',
  data () {
    return {}
  },
  beforeCreate () {
    console.time('time')
  },
  created () {
    if(window.sessionStorage.getItem('orange_lang')){
      this.$i18n.locale = window.sessionStorage.getItem('orange_lang')
    }else this.$i18n.locale = 'zh-CN'
    
  },
  mounted () {
    console.timeEnd('time')
  },
  methods: {

  }
}
</script>

<style>
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
  }
  #app{
    /* 1250px */
    min-width: 78.125rem;
    min-height: 700px;
  }
</style>
