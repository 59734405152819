import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/charts.js'
import '/node_modules/bootstrap-icons/font/bootstrap-icons.css'
import axios from './utils/http.js'
import common from './utils/common.js'
import AMap from './utils/map.js'
import i18n from './i18n/i18n.js'
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$common = common
Vue.prototype.$AMap = AMap
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
