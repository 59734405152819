import axios from './http.js'
// 获取用户信息
const userInfo = function () {
  return new Promise((outRes, outReq) => {
    axios({
      method: 'post',
      url: 'admin/userinfo'
    }).then((res) => {
      // console.log(res)
      outRes(res)
    }).catch((req) => {
      outReq(req)
    })
  })
}
// 获取指定cookie
const getCookie = function (cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
// 赋予cookie, cookie 的名字（cname），cookie 的值（cvalue），以及知道 cookie 过期的天数（exdays）
const setCookie = function (cname, cvalue, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}
/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
let timer1 = null // 防抖，
let timer2 = null // 节流
const Debounce = (fn, t) => {
  const delay = t || 500
  return function () {
    const args = arguments
    // let timer1 = null
    // console.log(timer1);
    if (timer1) {
      clearTimeout(timer1)
    }
    timer1 = setTimeout(() => {
      fn.apply(this, args)
      timer1 = null
    }, delay)
  }
}
// 使用
/*
Debounce(() => {
    //要执行的函数
}, 200)() */

/**
 * 函数节流
 * @param fn
 * @param interval
 * @returns {Function}
 * @constructor
 */
const Throttle = (fn, t) => {
  let last
  const interval = t || 500
  return function () {
    const args = arguments
    const now = +new Date()
    if (last && now - last < interval) {
      clearTimeout(timer2)
      timer2 = setTimeout(() => {
        last = now
        fn.apply(this, args)
      }, interval)
    } else {
      last = now
      fn.apply(this, args)
    }
  }
}
// 表单清空
const resetField = function (formName, vm) {
  // vm为当前实例
  vm.$refs[formName].resetFields()
}

/* 跳转外链下载 */
let lang = {
  "zh-CN": "zh",
  "zh-TW": "zhtw",
  "en": "en"
}
function postExcelFile (params, url) {
  var website = axios.defaults.baseURL
  if (url) url = website.concat(url)
  var form = document.createElement('form')
  form.style.display = 'none'
  form.action = url
  form.method = 'post'
  document.body.appendChild(form)
  params.token = `${window.sessionStorage.getItem('token')}`
  params.language = lang[window.sessionStorage.getItem('orange_lang')] || lang["zh-CN"]
  for (var key in params) {
    var input = document.createElement('input')
    input.type = 'hidden'
    input.name = key
    input.value = params[key]
    form.appendChild(input)
  }
  form.submit()
  form.remove()
}
// 时间补零
function ismm (t) { return t < 10 ? `0${t}` : t }
// 格式化时间
function formatTime (time) {
  if (time !== null) {
    var year = time.getFullYear()
    var Mon = time.getMonth() + 1
    var date = time.getDate()
    var hours = time.getHours()
    var min = time.getMinutes()
    var ss = time.getSeconds()
    return `${ismm(year)}-${ismm(Mon)}-${ismm(date)} ${ismm(hours)}:${ismm(min)}:${ismm(ss)}`
  } else {
    return ''
  }
}
// 遍历对象数据 方法一
function forData (data) {
  let key; const params = {}
  for (key in data) {
    if (data[key] !== ''&&data[key] !== null) params[key] = data[key]
  }
  return params
}
// 遍历对象数据 方法二 (源数据,想放入的对象)
function forData2 (data, form) {
  let key
  for (key in data) {
    if (key in form) form[key] = data[key]
  }
  return form
}
export default {
  userInfo,
  getCookie,
  setCookie,
  Debounce,
  Throttle,
  resetField,
  postExcelFile,
  formatTime,
  forData,
  forData2
}
